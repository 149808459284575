.podcasts-page {
    .podcasts-list {
        display: grid;
        // responsive grid layout
        gap: 1rem;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-auto-rows: minmax(100px, auto);
        overflow-x: auto;
    }
}

.platforms {
    display: grid;
    height: fit-content;
    gap: 1rem;

    .platform {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
}