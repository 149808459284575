@import '../variables';

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Noto Sans', sans-serif;
    background-color: white;
    font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $primary-color;
}

small {
    color: gray;
}

a {
    color: inherit;
    text-decoration: none;
}

img {
    width: 100%;
}

.error-message {
    background-color: lighten($color: $error-color, $amount: 25);
    padding: 0.5rem 1rem;
    border-radius: 0.4rem;
}

.success-message {
    background-color: lighten($color: $success-color, $amount: 25);
    padding: 0.5rem 1rem;
    border-radius: 0.4rem;
}

.full-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #F5F5F5;
}

button,
.button {
    padding: .65rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    outline: none;
    border: none;
    transition: all 0.3s;
    justify-content: center;
    gap: 0.5rem;
    text-wrap: nowrap;
}

section {
    padding: 3rem 0;
    display: grid;
    height: fit-content;
    gap: 2rem;
}

.call-to-action {
    background-color: #F2F8F7;
    padding: 3rem;
    border-radius: 0.4rem;

    display: grid;
    height: fit-content;
    gap: 1rem;
    place-content: center;

    form {
        display: flex;
        align-items: stretch;
        gap: 1rem;
        max-width: 600px;
        width: 100%;
        flex-wrap: wrap;

        input {
            flex: 1 1 100px;
            background-color: transparent;
        }

        button {
            flex: 1 1 20px;
            background-color: #00AAA1;
            color: white;
        }
    }
}

.primary-action {
    background-color: #00AAA1;
    color: white;

    .title {
        color: white;
    }

    form {
        input {
            color: white;
            border-color: white;

            &::placeholder {
                color: white;
            }
        }

        button {
            background-color: white;
            color: #00AAA1;
        }
    }
}

.small-button {
    padding: 0.5rem 0.8rem;
}

table {
    width: 100%;
    border-collapse: collapse;
    position: relative;

    th,
    td {
        padding: 0.7rem;
        // border-bottom: 1px solid #e1e4e8;
        text-align: start;
        font-size: 12px;
    }

    tr {
        &:nth-child(even) {
            background-color: #F2F8F7;
        }

        &:last-child {
            text-align: end;
        }
    }

    thead {
        background-color: #DFF1F0;
        position: sticky;
        // top: 176px;
        z-index: 1;
    }

    .table-items {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
}

.primary-button {
    background-color: $primary-color;
    color: white;

    &:hover {
        background-color: darken($primary-color, 5%);
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.outline-button {
    border: 1px solid $primary-color;
}

.form-button {
    padding: 0.8rem 2rem;
}

.full-button {
    width: 100%;
}

form,
.form-container {
    display: grid;
    height: fit-content;
    gap: 1rem;

    .form-group {
        display: grid;
        gap: 0.5rem;
        height: fit-content;
    }
}

.form-choices {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;

    .choice {
        // flex: 1 1;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.3rem 0.5rem;
        cursor: pointer;
        transition: background-color 0.3s;
        border-radius: 0.4rem;
    }
}

.buttons {
    display: flex;
    align-items: center;
    gap: 1rem;


    button,
    .button {
        flex: 1;
    }
}

.select {
    position: relative;

    .options {
        position: absolute;
        top: 3rem;
        left: 0;
        right: 0;
        background-color: white;
        padding: 1rem;
        border-radius: 0.4rem;
        border: 1px solid #ccc;
        max-height: 300px;
        overflow-y: auto;
        display: grid;

        .option {
            border-bottom: 1px solid #ccc;
            padding: 0.5rem 0;

            &:hover {
                background-color: #f2f2f2;
            }
        }

        &>:last-child {
            border-bottom: none;
        }
    }
}

input,
textarea,
select {
    flex: 1;
    padding: 0.8rem 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    border: 1px solid $border-color;
    color: $text-color;

    &::placeholder {
        color: $border-color;
    }

    &:focus {
        border-color: $primary-color;
    }
}

input[type="file"] {
    padding: 0;
    border: none;
}


.container {
    max-width: 1400px;
    margin-inline: auto;
}

.login-form {
    width: 100%;
    max-width: 600px;
    display: grid;
    height: fit-content;
    gap: 1rem;

    border: 1px solid #ccc;
    padding: 2rem;
    border-radius: 0.3rem;
}

.loading-icon {
    animation: loading-animation 1s infinite linear;
}

.link-field {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.pop-up {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);

    .popup-content {
        background-color: white;
        padding: 2rem;
        border-radius: 0.5rem;
        width: 100%;
        max-width: 600px;
        position: relative;

        .close-icon {
            position: absolute;
            top: 2rem;
            right: 2rem;
            cursor: pointer;
        }
    }

    .close-icon {
        position: absolute;
        top: 2rem;
        right: 2rem;
        cursor: pointer;
    }
}


.author-card {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    background-color: lighten($color: $primary-color, $amount: 65);
    border: 1px solid $primary-color;
    padding: 0.5rem;
    border-radius: 0.5rem;

    .name-icon {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
}

.name-initials {
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary-color;
    color: white;
    border-radius: 0.3rem;
    font-size: 14px;


}


.upload-image-container {
    display: grid;
    height: fit-content;
    gap: 0.5rem;
}

.error-page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    height: 70vh;
    width: 100%;
}

.coming-soon-page {
    height: 100vh;
    width: 100vw;
    background: url('../../images/comming-soon.webp');
    background-size: cover;
    background-position: center;
    background-blend-mode: overlay;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    backdrop-filter: blur(10px);

    .overlay {
        background-color: rgba(255, 255, 255, 0.273);
        backdrop-filter: blur(6px);
        height: 100vh;
        width: 100vw;
        position: absolute;
        left: 0;
        top: 0;

    }

    .container {
        max-width: 600px;
        padding: 2rem;
        text-align: center;
        display: grid;
        height: fit-content;
        align-content: center;
        justify-items: center;
        gap: 2rem;
        background-color: #ffffffbe;
        backdrop-filter: blur(5px);
        border-radius: 0.5rem;
        margin: 0 2rem;

        .button {
            width: fit-content;
        }

        img {
            max-width: 52px;
        }
    }

    .social-links {
        position: absolute;
        bottom: 4rem;
        right: 0;
        left: 0;
        text-align: center;
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: center;

        .links {
            text-align: center;
            display: flex;
            align-items: center;
            gap: 1rem;
            background-color: #ffffff2a;
            border-radius: 0.5rem;
            padding: 1rem;
            backdrop-filter: blur(12px);
            color: white;
        }
    }


}

@keyframes loading-animation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

// header

.branding {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .logo {
        max-width: 42px;
    }
}

.page-header {
    background-color: $fill-color;
    position: sticky;
    top: 0;
    z-index: 3000;

    .navigation {
        background-color: $fill-color;
        padding: 1rem 0;
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: space-between;
        color: #222222;

        .categories {
            display: flex;
            align-items: center;
            gap: 1rem;
        }

        .mobile-search {
            display: none;
        }

        .actions,
        .social-icons {
            display: flex;
            align-items: center;
            gap: 1rem;
        }

        .menu-icon {
            display: none;
            flex-direction: column;
            align-items: end;
            justify-content: center;
            gap: 0.5rem;
            position: relative;

            .line {
                width: 2.5rem;
                height: 0.3rem;
                background-color: $primary-color;
                border-radius: 2px;
                transition: all 0.5s ease;
            }

            .b {
                width: 1.5rem;
            }
        }

        .link {
            cursor: pointer;
        }

    }

}

.archive-page {
    .archive-content {
        padding: 6rem 1rem;
    }
}

.note {
    background-color: $primary-color;
    color: white;

    .container {
        display: flex;
        align-items: center;
        padding: 0.5rem 0;
        gap: 1rem;
    }

}

.popup {
    position: fixed;
    padding: 1rem;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.579);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;

    .popup-content {
        max-width: 600px;
        width: 100%;
        background-color: white;
        padding: 3rem;
        border-radius: 1rem;
        border: 1px solid rgb(225, 225, 225);
        display: grid;
        height: fit-content;
        gap: 1rem;
    }
}


@media screen and (max-width: 1460px) {
    header {
        .container {
            padding: 0 1rem;
        }
    }
}


@media screen and (max-width: 1280px) {
    .note {
        padding: 0.5rem 1rem;
    }

    .container {
        max-width: 100vw;
        padding: 0 1rem;
    }

    .branding {
        justify-content: start;
    }

    .page-header {
        height: 5rem;
        transition: all 0.5s ease;

        .navigation {
            padding: 1rem;
            position: relative;
            justify-content: space-between;

            .mobile-search {
                display: block;
                position: absolute;
                right: 4.5rem;
            }

            .categories,
            .actions {
                display: none;
            }

            .menu-icon {
                display: flex;
            }
        }
    }

    .menu-open {

        .a {
            transform: rotate(45deg);
            position: absolute;
        }

        .c {
            transform: rotate(-45deg);
            position: absolute;
        }

        .b {
            display: none;
        }
    }

    .nav-open {
        height: 100vh;
        transition: all 0.5s linear forwards;
        overflow: hidden;

        .navigation {
            .categories {
                display: flex;
                flex-direction: column;
                position: absolute;
                justify-content: center;
                align-items: center;
                top: 5rem;
                left: 0;
                width: 100%;

                font-size: 2rem;
            }

            .actions {
                display: flex;
                // font-size: 2rem;
                position: absolute;
                justify-content: center;
                align-items: center;
                top: 75vh;
                left: 0;
                width: 100%;

                .search {
                    display: none;
                }
            }
        }
    }
}

@keyframes appear {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}