.sidebar {
    width: 400px;
    position: sticky;
    top: 250px;
    display: grid;
    height: fit-content;
    gap: 2rem;
    padding: 1rem;
    border-radius: 0.4rem;
    border: 1px solid whitesmoke;
}

.home-articles {
    display: flex;
    align-items: baseline;
    gap: 3rem;
    position: relative;

    .articles-container,
    .detail-article {

        flex: 1;
    }

    .detail-article {
        padding: 6rem 0;
    }
}

.articles-list {
    display: grid;
    gap: 1rem;

    &>:nth-child(odd) {
        .category {
            background-color: #DFF1F0;
            color: gray
        }
    }

    &>:nth-child(even) {
        .category {
            background-color: #00AAA1;
            color: white;
        }
    }

    .article {
        display: flex;
        align-items: center;
        border-radius: 0.4rem;
        transition: 0.3s linear;

        .image {
            .thumbnail {
                height: 180px;
                width: 266px;
                object-fit: cover;
            }
        }

        .article-content {
            display: grid;
            height: fit-content;
            gap: 0.8rem;
        }

        &:hover {
            background-color: #F7F7F7;
        }

        small {
            font-size: 0.8rem;
        }
    }
}

.categories-list {
    display: grid;
    height: fit-content;
    gap: 1rem;

    .category {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0;
        border-bottom: 1px solid whitesmoke;
        transition: all 0.3s linear;

        .link {
            display: flex;
            align-items: center;
            gap: 0.3rem;

            .icon {
                display: none;
            }
        }

        &:hover {
            border-radius: 0.4rem;
            padding: 1rem;
            background-color: #00AAA1;
            color: white;
            border-bottom: none;

            .link {
                .icon {
                    display: block;
                }
            }
        }
    }

    .category:last-child {
        border-bottom: none;
    }

}

.mobile-call-to-action {
    display: none;

}


.archive-content {
    padding: 6rem 0;
    display: grid;
    height: fit-content;
    gap: 2rem;


    .articles-list {
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));

        .article {
            display: grid;
            height: fit-content;

            .image {
                .thumbnail {
                    height: 200px;
                    width: 100%;
                }
            }

            .metas {
                .read-time {
                    display: none;
                }
            }

            .title {
                font-size: 1rem;
            }

            .category {
                display: none;
            }
        }
    }
}

.home-articles {
    padding: 5rem 0;

    .detail-article {
        display: grid;
        height: fit-content;
        gap: 1rem;

        .category {
            width: fit-content;
            padding: 0.2rem 0.5rem;
            border-radius: 0.4rem;
            font-size: 1rem;
            background-color: #DFF1F0;
        }

        .metas {
            font-size: 1rem;
        }

        .featured-image {
            aspect-ratio: 16/9;
            object-fit: cover;
        }

        .excerpts {
            padding: 1rem;
            background-color: #DFF1F0;
            border-radius: 0.5rem;
        }
    }
}


@media screen and (max-width: 1180px) {
    .sidebar {
        min-width: 250px;
    }
}

@media screen and (max-width: 980px) {
    .sidebar {
        display: none;
    }

    .mobile-call-to-action {
        display: grid;
    }
}

@media screen and (max-width: 860px) {
    .articles-list {
        .article {
            .image {
                .thumbnail {
                    height: 120px;
                    width: 190px;
                }
            }

            .article-content {
                .title {
                    font-size: 1rem;
                }

                .excerpts {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width:540px) {
    .articles-list {
        .article {
            .image {
                .thumbnail {
                    width: 120px;
                }
            }

            .metas {
                .author {
                    display: none;
                }

                span {
                    display: none;
                }
            }
        }
    }
}