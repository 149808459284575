.hero-section {
  padding: 6rem 0;
  background-color: #F2F8F7;
}
.hero-section .container {
  max-width: 1280px;
  width: 100%;
  display: flex;
  align-items: baseline;
  gap: 4rem;
  flex-wrap: wrap;
}
.hero-section .container .articles-container,
.hero-section .container .podcasts-container {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}
.hero-section .container .articles-container {
  flex: 1 1 440px;
}
.hero-section .container .podcasts-container {
  flex: 1;
  min-width: 300px;
}

.section-title {
  font-size: 1.5rem;
  color: #222222;
}
.section-title span {
  background-color: #00AAA1;
  color: white;
}

.articles {
  flex: 1 1 300px;
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}
.articles > :nth-child(odd) .category {
  background-color: #DFF1F0;
  color: gray;
}
.articles > :nth-child(even) .category {
  background-color: #00AAA1;
  color: white;
}

.article {
  flex: 1 1 200px;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 0.8rem;
}
.article .title {
  color: #222222;
  font-size: 1.2rem;
  line-height: 1.2;
}
.article .category {
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.4rem 0.5rem;
  border-radius: 0.4rem;
  font-size: 0.8rem;
}
.article .thumbnail {
  width: 100%;
  height: 200px;
  -o-object-fit: cover;
     object-fit: cover;
}
.article .excerpts {
  font-size: 1rem;
  color: rgb(100, 100, 100);
}

.metas {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 0.8rem;
  color: gray;
}
.metas .meta {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}
.metas .meta .icon {
  color: black;
}

.podcasts {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.podcasts > :nth-child(odd) .category {
  background-color: #DFF1F0;
  color: gray;
}
.podcasts > :nth-child(even) .category {
  background-color: #00AAA1;
  color: white;
}

.thumbnail {
  border-radius: 0.4rem;
}

.podcast {
  flex: 1 1 250px;
  display: flex;
  align-items: stretch;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}
.podcast .image {
  position: relative;
  max-width: 100px;
  width: 100%;
  height: 100px;
}
.podcast .image .thumbnail {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.podcast .podcast-content {
  flex: 1;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 0.3rem;
}
.podcast .podcast-content .category {
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.2rem 0.5rem;
  border-radius: 0.4rem;
  font-size: 0.7rem;
}
.podcast .podcast-content .title {
  color: #222222;
  font-size: 0.8rem;
  line-height: 1.2;
}
.podcast .podcast-content .description {
  font-size: 0.8rem;
  color: gray;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.638);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 0.4rem;
}

@media screen and (max-width: 1320px) {
  .hero-section .container {
    padding: 0 1rem;
  }
}/*# sourceMappingURL=heroSection.css.map */