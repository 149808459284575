.sidebar {
  width: 400px;
  position: sticky;
  top: 250px;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 2rem;
  padding: 1rem;
  border-radius: 0.4rem;
  border: 1px solid whitesmoke;
}

.home-articles {
  display: flex;
  align-items: baseline;
  gap: 3rem;
  position: relative;
}
.home-articles .articles-container,
.home-articles .detail-article {
  flex: 1;
}
.home-articles .detail-article {
  padding: 6rem 0;
}

.articles-list {
  display: grid;
  gap: 1rem;
}
.articles-list > :nth-child(odd) .category {
  background-color: #DFF1F0;
  color: gray;
}
.articles-list > :nth-child(even) .category {
  background-color: #00AAA1;
  color: white;
}
.articles-list .article {
  display: flex;
  align-items: center;
  border-radius: 0.4rem;
  transition: 0.3s linear;
}
.articles-list .article .image .thumbnail {
  height: 180px;
  width: 266px;
  -o-object-fit: cover;
     object-fit: cover;
}
.articles-list .article .article-content {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 0.8rem;
}
.articles-list .article:hover {
  background-color: #F7F7F7;
}
.articles-list .article small {
  font-size: 0.8rem;
}

.categories-list {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}
.categories-list .category {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 1px solid whitesmoke;
  transition: all 0.3s linear;
}
.categories-list .category .link {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.categories-list .category .link .icon {
  display: none;
}
.categories-list .category:hover {
  border-radius: 0.4rem;
  padding: 1rem;
  background-color: #00AAA1;
  color: white;
  border-bottom: none;
}
.categories-list .category:hover .link .icon {
  display: block;
}
.categories-list .category:last-child {
  border-bottom: none;
}

.mobile-call-to-action {
  display: none;
}

.archive-content {
  padding: 6rem 0;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 2rem;
}
.archive-content .articles-list {
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
}
.archive-content .articles-list .article {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
}
.archive-content .articles-list .article .image .thumbnail {
  height: 200px;
  width: 100%;
}
.archive-content .articles-list .article .metas .read-time {
  display: none;
}
.archive-content .articles-list .article .title {
  font-size: 1rem;
}
.archive-content .articles-list .article .category {
  display: none;
}

.home-articles {
  padding: 5rem 0;
}
.home-articles .detail-article {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}
.home-articles .detail-article .category {
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.2rem 0.5rem;
  border-radius: 0.4rem;
  font-size: 1rem;
  background-color: #DFF1F0;
}
.home-articles .detail-article .metas {
  font-size: 1rem;
}
.home-articles .detail-article .featured-image {
  aspect-ratio: 16/9;
  -o-object-fit: cover;
     object-fit: cover;
}
.home-articles .detail-article .excerpts {
  padding: 1rem;
  background-color: #DFF1F0;
  border-radius: 0.5rem;
}

@media screen and (max-width: 1180px) {
  .sidebar {
    min-width: 250px;
  }
}
@media screen and (max-width: 980px) {
  .sidebar {
    display: none;
  }
  .mobile-call-to-action {
    display: grid;
  }
}
@media screen and (max-width: 860px) {
  .articles-list .article .image .thumbnail {
    height: 120px;
    width: 190px;
  }
  .articles-list .article .article-content .title {
    font-size: 1rem;
  }
  .articles-list .article .article-content .excerpts {
    display: none;
  }
}
@media screen and (max-width: 540px) {
  .articles-list .article .image .thumbnail {
    width: 120px;
  }
  .articles-list .article .metas .author {
    display: none;
  }
  .articles-list .article .metas span {
    display: none;
  }
}/*# sourceMappingURL=articlesList.css.map */