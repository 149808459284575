.hero-section {
    padding: 6rem 0;
    background-color: #F2F8F7;

    .container {
        max-width: 1280px;
        width: 100%;
        display: flex;
        align-items: baseline;
        gap: 4rem;
        flex-wrap: wrap;

        .articles-container,
        .podcasts-container {
            display: grid;
            height: fit-content;
            gap: 1rem;
        }

        .articles-container {
            flex: 1 1 440px;
        }

        .podcasts-container {
            flex: 1;
            min-width: 300px;
        }
    }

}

.section-title {
    font-size: 1.5rem;
    color: #222222;

    span {
        background-color: #00AAA1;
        color: white;
    }
}



.articles {
    flex: 1 1 300px;
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    &>:nth-child(odd) {
        .category {
            background-color: #DFF1F0;
            color: gray
        }
    }

    &>:nth-child(even) {
        .category {
            background-color: #00AAA1;
            color: white;
        }
    }
}

.article {
    flex: 1 1 200px;
    display: grid;
    height: fit-content;
    gap: 0.8rem;

    .title {
        color: #222222;
        font-size: 1.2rem;
        line-height: 1.2;
    }

    .category {
        width: fit-content;
        padding: 0.4rem 0.5rem;
        border-radius: 0.4rem;
        font-size: 0.8rem;
    }

    .thumbnail {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }


    .excerpts {
        font-size: 1rem;
        color: rgb(100, 100, 100);
    }
}

.metas {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 0.8rem;
    color: gray;

    .meta {
        display: flex;
        align-items: center;
        gap: 0.2rem;

        .icon {
            color: black;
        }
    }
}


.podcasts {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;

    &>:nth-child(odd) {
        .category {
            background-color: #DFF1F0;
            color: gray
        }
    }

    &>:nth-child(even) {
        .category {
            background-color: #00AAA1;
            color: white;
        }
    }
}

.thumbnail {
    border-radius: 0.4rem;
}

.podcast {
    flex: 1 1 250px;
    display: flex;
    align-items: stretch;
    height: fit-content;
    gap: 1rem;

    .image {
        position: relative;
        max-width: 100px;
        width: 100%;
        height: 100px;

        .thumbnail {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .podcast-content {
        flex: 1;
        display: grid;
        height: fit-content;
        gap: 0.3rem;

        .category {
            width: fit-content;
            padding: 0.2rem 0.5rem;
            border-radius: 0.4rem;
            font-size: 0.7rem;
        }

        .title {
            color: #222222;
            font-size: 0.8rem;
            line-height: 1.2;
        }

        .description {
            font-size: 0.8rem;
            color: gray;
        }
    }

}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.638);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 0.4rem;
}


@media screen and (max-width: 1320px) {
    .hero-section {
        .container {
            padding: 0 1rem;
        }
    }
}