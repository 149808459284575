@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Noto Sans", sans-serif;
  background-color: white;
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #00AAA1;
}

small {
  color: gray;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  width: 100%;
}

.error-message {
  background-color: #f6d6d5;
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
}

.success-message {
  background-color: #d1ebd1;
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
}

.full-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #F5F5F5;
}

button,
.button {
  padding: 0.65rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  transition: all 0.3s;
  justify-content: center;
  gap: 0.5rem;
  text-wrap: nowrap;
}

section {
  padding: 3rem 0;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 2rem;
}

.call-to-action {
  background-color: #F2F8F7;
  padding: 3rem;
  border-radius: 0.4rem;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
  place-content: center;
}
.call-to-action form {
  display: flex;
  align-items: stretch;
  gap: 1rem;
  max-width: 600px;
  width: 100%;
  flex-wrap: wrap;
}
.call-to-action form input {
  flex: 1 1 100px;
  background-color: transparent;
}
.call-to-action form button {
  flex: 1 1 20px;
  background-color: #00AAA1;
  color: white;
}

.primary-action {
  background-color: #00AAA1;
  color: white;
}
.primary-action .title {
  color: white;
}
.primary-action form input {
  color: white;
  border-color: white;
}
.primary-action form input::-moz-placeholder {
  color: white;
}
.primary-action form input::placeholder {
  color: white;
}
.primary-action form button {
  background-color: white;
  color: #00AAA1;
}

.small-button {
  padding: 0.5rem 0.8rem;
}

table {
  width: 100%;
  border-collapse: collapse;
  position: relative;
}
table th,
table td {
  padding: 0.7rem;
  text-align: start;
  font-size: 12px;
}
table tr:nth-child(even) {
  background-color: #F2F8F7;
}
table tr:last-child {
  text-align: end;
}
table thead {
  background-color: #DFF1F0;
  position: sticky;
  z-index: 1;
}
table .table-items {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.primary-button {
  background-color: #00AAA1;
  color: white;
}
.primary-button:hover {
  background-color: #009189;
}
.primary-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.outline-button {
  border: 1px solid #00AAA1;
}

.form-button {
  padding: 0.8rem 2rem;
}

.full-button {
  width: 100%;
}

form,
.form-container {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}
form .form-group,
.form-container .form-group {
  display: grid;
  gap: 0.5rem;
  height: -moz-fit-content;
  height: fit-content;
}

.form-choices {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.form-choices .choice {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.3rem 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 0.4rem;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.buttons button,
.buttons .button {
  flex: 1;
}

.select {
  position: relative;
}
.select .options {
  position: absolute;
  top: 3rem;
  left: 0;
  right: 0;
  background-color: white;
  padding: 1rem;
  border-radius: 0.4rem;
  border: 1px solid #ccc;
  max-height: 300px;
  overflow-y: auto;
  display: grid;
}
.select .options .option {
  border-bottom: 1px solid #ccc;
  padding: 0.5rem 0;
}
.select .options .option:hover {
  background-color: #f2f2f2;
}
.select .options > :last-child {
  border-bottom: none;
}

input,
textarea,
select {
  flex: 1;
  padding: 0.8rem 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  border: 1px solid #999999;
  color: #363636;
}
input::-moz-placeholder, textarea::-moz-placeholder, select::-moz-placeholder {
  color: #999999;
}
input::placeholder,
textarea::placeholder,
select::placeholder {
  color: #999999;
}
input:focus,
textarea:focus,
select:focus {
  border-color: #00AAA1;
}

input[type=file] {
  padding: 0;
  border: none;
}

.container {
  max-width: 1400px;
  margin-inline: auto;
}

.login-form {
  width: 100%;
  max-width: 600px;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
  border: 1px solid #ccc;
  padding: 2rem;
  border-radius: 0.3rem;
}

.loading-icon {
  animation: loading-animation 1s infinite linear;
}

.link-field {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.pop-up {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}
.pop-up .popup-content {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 600px;
  position: relative;
}
.pop-up .popup-content .close-icon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
}
.pop-up .close-icon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
}

.author-card {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  background-color: #f7ffff;
  border: 1px solid #00AAA1;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.author-card .name-icon {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.name-initials {
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00AAA1;
  color: white;
  border-radius: 0.3rem;
  font-size: 14px;
}

.upload-image-container {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 0.5rem;
}

.error-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  height: 70vh;
  width: 100%;
}

.coming-soon-page {
  height: 100vh;
  width: 100vw;
  background: url("../../images/comming-soon.webp");
  background-size: cover;
  background-position: center;
  background-blend-mode: overlay;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}
.coming-soon-page .overlay {
  background-color: rgba(255, 255, 255, 0.273);
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
}
.coming-soon-page .container {
  max-width: 600px;
  padding: 2rem;
  text-align: center;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  align-content: center;
  justify-items: center;
  gap: 2rem;
  background-color: rgba(255, 255, 255, 0.7450980392);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border-radius: 0.5rem;
  margin: 0 2rem;
}
.coming-soon-page .container .button {
  width: -moz-fit-content;
  width: fit-content;
}
.coming-soon-page .container img {
  max-width: 52px;
}
.coming-soon-page .social-links {
  position: absolute;
  bottom: 4rem;
  right: 0;
  left: 0;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}
.coming-soon-page .social-links .links {
  text-align: center;
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: rgba(255, 255, 255, 0.1647058824);
  border-radius: 0.5rem;
  padding: 1rem;
  -webkit-backdrop-filter: blur(12px);
          backdrop-filter: blur(12px);
  color: white;
}

@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.branding {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.branding .logo {
  max-width: 42px;
}

.page-header {
  background-color: #E8F3F3;
  position: sticky;
  top: 0;
  z-index: 3000;
}
.page-header .navigation {
  background-color: #E8F3F3;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  color: #222222;
}
.page-header .navigation .categories {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.page-header .navigation .mobile-search {
  display: none;
}
.page-header .navigation .actions,
.page-header .navigation .social-icons {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.page-header .navigation .menu-icon {
  display: none;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  gap: 0.5rem;
  position: relative;
}
.page-header .navigation .menu-icon .line {
  width: 2.5rem;
  height: 0.3rem;
  background-color: #00AAA1;
  border-radius: 2px;
  transition: all 0.5s ease;
}
.page-header .navigation .menu-icon .b {
  width: 1.5rem;
}
.page-header .navigation .link {
  cursor: pointer;
}

.archive-page .archive-content {
  padding: 6rem 1rem;
}

.note {
  background-color: #00AAA1;
  color: white;
}
.note .container {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  gap: 1rem;
}

.popup {
  position: fixed;
  padding: 1rem;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.579);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup .popup-content {
  max-width: 600px;
  width: 100%;
  background-color: white;
  padding: 3rem;
  border-radius: 1rem;
  border: 1px solid rgb(225, 225, 225);
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}

@media screen and (max-width: 1460px) {
  header .container {
    padding: 0 1rem;
  }
}
@media screen and (max-width: 1280px) {
  .note {
    padding: 0.5rem 1rem;
  }
  .container {
    max-width: 100vw;
    padding: 0 1rem;
  }
  .branding {
    justify-content: start;
  }
  .page-header {
    height: 5rem;
    transition: all 0.5s ease;
  }
  .page-header .navigation {
    padding: 1rem;
    position: relative;
    justify-content: space-between;
  }
  .page-header .navigation .mobile-search {
    display: block;
    position: absolute;
    right: 4.5rem;
  }
  .page-header .navigation .categories,
  .page-header .navigation .actions {
    display: none;
  }
  .page-header .navigation .menu-icon {
    display: flex;
  }
  .menu-open .a {
    transform: rotate(45deg);
    position: absolute;
  }
  .menu-open .c {
    transform: rotate(-45deg);
    position: absolute;
  }
  .menu-open .b {
    display: none;
  }
  .nav-open {
    height: 100vh;
    transition: all 0.5s linear forwards;
    overflow: hidden;
  }
  .nav-open .navigation .categories {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 5rem;
    left: 0;
    width: 100%;
    font-size: 2rem;
  }
  .nav-open .navigation .actions {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 75vh;
    left: 0;
    width: 100%;
  }
  .nav-open .navigation .actions .search {
    display: none;
  }
}
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}/*# sourceMappingURL=main.css.map */